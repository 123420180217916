import React from "react"
import MainLayout from "@/layouts/MainLayout"
import Promo from "@/components/Promo"

export default () => {
  return (
    <MainLayout>
      <Promo />
    </MainLayout>
  )
}
