import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className={style.promo}>
      <div className="op__block">
        <div className="container-fluid">
          <div className={style.promo__inner}>
            <div className="row">
              <div className="col">
                <h1
                  data-aos="fade-right"
                  style={{ fontWeight: 500, wordWrap: "break-word" }}
                >
                  Portal de Desenvolvimento
                  <br /> da{" "}
                  <strong style={{ color: "#4a69bd", fontWeight: 700 }}>
                    Partnr
                  </strong>
                  .
                </h1>
                <ul
                  className={style.promo__list}
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <li>
                    <span>
                      <a
                        href="https://docs.partnr.ai/docs/apis/Reports-API.yaml"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Company Reports API
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <a
                        href="https://docs.partnr.ai/docs/apis/Insider-API.yaml"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Insider Trading API
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <a
                        href="https://docs.partnr.ai/docs/apis/Brazil-Companies-API.yaml/paths/~1companies/get"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Brazil Companies API
                      </a>
                    </span>
                  </li>
                  {/* <li>
                    <span
                      style={{
                        color: "#959595",
                      }}
                    >
                      Factor Investing API{" "}
                      <span style={{ fontSize: "16px" }}>
                        (em desenvolvimento)
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        color: "#959595",
                      }}
                    >
                      Company Scores API{" "}
                      <span style={{ fontSize: "16px" }}>
                        (em desenvolvimento)
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        color: "#959595",
                      }}
                    >
                      Score-Making API{" "}
                      <span style={{ fontSize: "16px" }}>
                        (em desenvolvimento)
                      </span>
                    </span>
                  </li> */}
                </ul>
                <div className="d-flex row align-items-center justify-content-center justify-content-sm-start px-3">
                  <a href="/" className="op__btn op__btn--lightDark mr-3">
                    Suporte
                  </a>
                  <a
                    href="https://api.whatsapp.com/message/W7U5OVSUZHLEO1"
                    className="op__btn"
                  >
                    Contratar
                  </a>
                </div>
              </div>
              <div
                className="col d-flex align-items-center justify-content-center justify-content-sm-end mt-5"
                data-aos="fade-up"
                data-aos-delay="300"
                style={{
                  minWidth: "50%",
                  maxHeight: "100%",
                }}
              >
                <img
                  src="/resources/img/iMac.png"
                  alt="APIs"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
